import { useEffect, useId, useRef } from 'react';
import styled from 'styled-components';
import { useJsApiLoader } from '@react-google-maps/api';

import { Colors } from '../../../styles/colors';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export function GoogleMap({ center, home, zoom = 12 }: GoogleMapProps) {
  const mapId = useId();
  const mapRef = useRef<HTMLDivElement | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAP_API,
  });

  useEffect(() => {
    if (!isLoaded) return;
    if (!center.lat || !center.lng) return;

    async function initMap() {
      const { Map, InfoWindow } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
      const { AdvancedMarkerElement, PinElement } = (await google.maps.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary;

      // Create an info window to share between markers.
      const infoWindow = new InfoWindow();

      const pinScaledJob = new PinElement({
        scale: 1.2,
        background: Colors.ContrastDarkest,
        borderColor: Colors.Primary,
        glyphColor: Colors.Primary,
      });

      const pinScaledHome = new PinElement({
        scale: 1.2,
        background: Colors.Primary,
        borderColor: Colors.ContrastDarkest,
        glyphColor: Colors.ContrastDarkest,
      });

      if (mapRef.current) {
        const map = new Map(mapRef.current, {
          center,
          zoom,
          mapId,
        });

        const jobMarker = new AdvancedMarkerElement({
          map,
          position: center,
          content: pinScaledJob.element,
        });
        jobMarker.addListener('click', () => {
          infoWindow.close();
          infoWindow.setContent(`
            <div>
              <h3>Job Application</h3>
            </div>
          `);
          infoWindow.open(jobMarker.map, jobMarker);
        });

        // Home marker
        if (home.lat && home.lng) {
          const homeMarker = new AdvancedMarkerElement({
            map,
            position: home,
            content: pinScaledHome.element,
          });
          homeMarker.addListener('click', () => {
            infoWindow.close();
            infoWindow.setContent(`
            <div>
              <h4>HOME</h4>
            </div>
          `);
            infoWindow.open(homeMarker.map, homeMarker);
          });
        }
      }
    }
    initMap();
  }, [isLoaded]);

  return (
    <ErrorBoundary>
      <Wrapper>
        {/* <pre>{JSON.stringify(center, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(zoom, null, 2)}</pre> */}
        <div ref={mapRef} style={{ height: '100%' }}></div>
      </Wrapper>
    </ErrorBoundary>
  );
}

// Important! Always set the container height explicitly
const Wrapper = styled.div`
  border: 1px solid ${Colors.Primary};
  height: 100%;
  width: 100%;
  margin-bottom: 32px;
`;

type GoogleMapProps = {
  home: {
    lat?: number;
    lng?: number;
  };
  center: {
    lat?: number;
    lng?: number;
  };
  zoom?: number;
};
